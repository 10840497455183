import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }
  static targets = ['leadAppraiserId', 'ownerId', 'onlineAppraisal']

  async updateOnlineAppraisalOptionsForAppraiser() {
    const userId = this.leadAppraiserIdTarget.value

    if (!userId) {
      this.#clearOnlineAppraisalOptions()
      return
    }

    this.onlineAppraisalTarget.disabled = true

    const url = `${this.urlValue}?user_id=${userId}`
    const response = await get(url, {
      contentType: 'application/json',
      responseKind: 'json'
    })

    if (response.ok) {
      const data = await response.json
      this.#updateSelectOptions(data.online_appraisals)
    }

    this.onlineAppraisalTarget.disabled = false
  }

  async updateOnlineAppraisalOptionsForAppraisee() {
    const userId = this.ownerIdTarget.value

    if (!userId) {
      this.#clearOnlineAppraisalOptions()
      return
    }

    this.onlineAppraisalTarget.disabled = true

    const url = `${this.urlValue}?user_id=${userId}`
    const response = await get(url, {
      contentType: 'application/json',
      responseKind: 'json'
    })

    if (response.ok) {
      const data = await response.json
      this.#updateSelectOptions(data.online_appraisals)
    }

    this.onlineAppraisalTarget.disabled = false
  }

  #clearOnlineAppraisalOptions() {
    const select = this.onlineAppraisalTarget
    select.innerHTML = ''
  }

  #updateSelectOptions(appraisals) {
    const select = this.onlineAppraisalTarget
    select.innerHTML = ''

    if (appraisals.length === 0) {
      const option = document.createElement('option')
      option.text = 'There are no appraisals for this user.'
      select.appendChild(option)
    } else {
      appraisals.forEach(([id, name, date]) => {
        const option = document.createElement('option')
        option.value = id
        option.text = `${date} - ${name}`
        select.appendChild(option)
      })
    }
  }
}
