// repeats_every_n_weeks (select), repeats_weekly_each_days_of_the_week (day boxes),
// repeats_rota (week circles) management

// TODO refactor more by using targets
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['everyWeeks', 'weeksOn', 'repeatsRota', 'onWeeks']

  connect(){
    // get value of repeats_every_n_weeks select 'Every' (1..52) 'week(s)' and use to show relevant week circles
    const everyWeeks = this.everyWeeksTarget.value

    this.pluralizeWeek(everyWeeks)

    if (everyWeeks > 1) {
      this.hideAllWeekCircles()
      this.repeatsRotaTarget.classList.remove('d-none')
      this.showWeekCircles(everyWeeks)
    }

    const repeats_rota = Array.from(document.getElementById('job_content_repeats_rota').selectedOptions).map(option => option.value)
    this.selectWeekCircles(repeats_rota)

    const days_of_the_week = Array.from(document.getElementById('job_content_repeats_weekly_each_days_of_the_week').selectedOptions).map(option => option.value)
    this.selectDaysOfTheWeek(days_of_the_week)
  }

  // when select an option from the on 'Every' (1..52) 'week(s)' select
  onSelectEveryWeeksOption(event) {
    const everyWeeks = this.everyWeeksTarget.value

    // deselect all 'On Weeks' outside 'Every n weeks on' Range
    const onWeeks = [...this.onWeeksTarget.options].filter(option => option.selected).map(option => option.value)

    if(everyWeeks && onWeeks.length > 0 && _.last(onWeeks) > everyWeeks) {
      const selected_on_weeks = _.filter(onWeeks, (w) => { return(parseInt(w) <= parseInt(everyWeeks)) })

      Array.from(this.onWeeksTarget.options).forEach(option => {
        option.selected = selected_on_weeks.includes(option.value)
      })

      const deselect_circles = _.difference(onWeeks, selected_on_weeks)
      deselect_circles.forEach(value => {
        document.querySelector(`.rota-week-selector td[data-value='${value}']`).classList.remove('selected')
      })
    }

    this.pluralizeWeek(everyWeeks)

    if (everyWeeks > 1) {
      this.repeatsRotaTarget.classList.remove('d-none')
    } else {
      this.repeatsRotaTarget.classList.add('d-none')
    }

    this.hideAllWeekCircles()
    this.showWeekCircles(everyWeeks)
  }

  // when click on week circle
  onClickWeekCircle(event) {
    // Toggle selected class
    event.target.classList.toggle('selected')

    // Get all selected weeks
    const selected_weeks = Array.from(event.target.closest('tr').querySelectorAll('td.clickable_td.selected'))
      .map(td => td.getAttribute('data-value'))

    // Update multiple select - select/deselect options based on selected weeks
    const selectElement = document.getElementById('job_content_repeats_rota')
    Array.from(selectElement.options).forEach(option => {
      option.selected = selected_weeks.includes(option.value)
    })
  }

  // when click on day of the week
  onClickDay(event) {
    // add selected class
    const td_el = event.target.closest('td.clickable_td')
    td_el.classList.toggle('selected')

    // Update hidden array field using modern JavaScript
    const selected_days = Array.from(td_el.closest('tr').querySelectorAll('td.selected')).map(td => td.getAttribute('data-value'))
    const selectElement = document.getElementById('job_content_repeats_weekly_each_days_of_the_week')
    Array.from(selectElement.options).forEach(option => {
      option.selected = selected_days.includes(option.value)
    })
  }

  hideAllWeekCircles() {
    document.querySelectorAll('.rota-week-selector td.clickable_td').forEach(el => el.classList.add('d-none'))
  }

  showWeekCircles(everyWeeks) {
    // show clickable_td for week options if repeating less than once a week
    if (everyWeeks > 1) {
      this.hideAllWeekCircles()
      this.repeatsRotaTarget.classList.remove('d-none')
      everyWeeks = parseInt(everyWeeks, 10)
      ++everyWeeks
      const range = [...Array(everyWeeks - 1)].map((_, i) => i + 1)
      range.forEach(value => {
        document.querySelector(`td.clickable_td[data-value='${value}']`).classList.remove('d-none')
      });
    }
  }

  selectWeekCircles(repeats_rota) {
    if (repeats_rota) {
      repeats_rota.forEach(value => {
        document.querySelector(`td.clickable_td[data-value='${value}']`).classList.add('selected')
      })
    }
  }

  selectDaysOfTheWeek(days_of_the_week) {
    if (days_of_the_week) {
      days_of_the_week.forEach(value => {
        document.querySelector(`td.clickable_td[data-value='${value}']`).classList.add('selected')
      })
    }
  }

  pluralizeWeek(count){
    const new_text = count > 1 ? 'weeks' : 'week'
    this.weeksOnTarget.textContent = new_text
  }
}
