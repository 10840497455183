import TextareaAutogrow from "stimulus-textarea-autogrow"

export default class extends TextareaAutogrow {
  connect() {
    const isPdf = document.querySelector('meta[name="pdf"]')
    //const isPdf = document.querySelector('body').className.includes('pdf')

    if(isPdf) {
      // in pdf convert textareas to paragraphs
      const dom_id = this.element.id

      let para = document.createElement('p')
      para.id = dom_id // copy dom_id from converted textarea

      let content = this.element.innerHTML
      content = content.replace(/(?:\r\n|\r|\n)/g, '<br/>')

      para.innerHTML = content
      this.element.replaceWith(para)
    } else {
      super.connect()

      if(this.element.scrollHeight == 0) { // element is initially hidden
        this.element.style.height = `${(this.linesCount() * this.lineHeight()) + this.getPaddingsSum()}px`
      }
    }
  }

  getStyle(el, styleProp) {
    let y = null
    if (el.currentStyle)
      y = el.currentStyle[styleProp]
    else if (window.getComputedStyle)
      y = document.defaultView.getComputedStyle(el,null).getPropertyValue(styleProp)
    return y
  }

  linesCount() {
    let count = this.element.value.split("\n").length || this.element.rows
    if(count == 1) count++ // at least 2 lines
    return count
  }

  lineHeight() {
    let height_px = this.getStyle(this.element, 'line-height')
    return parseInt(height_px) || 0
  }

  getPaddingsSum() {
    let sum = 0
    let padding_top_px = this.getStyle(this.element, 'padding-top')
    if(padding_top_px) {
      sum += parseInt(padding_top_px) || 0
    }
    let padding_bottom_px = this.getStyle(this.element, 'padding-bottom')
    if(padding_bottom_px) {
      sum += parseInt(padding_bottom_px) || 0
    }
    return sum
  }
}
