import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { canEdit: { type: Boolean, default: true } }

  connect(){
    this._applyPopovers()

    if(!this.canEditValue) {
      this.disableForm()
    }
    // scroll to top when edit activity
    this.element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  _applyPopovers() {
    let popoverTriggerList = [].slice.call(this.element.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
    })
  }

  disableForm() {
    // Form inputs
    document.querySelectorAll('input').forEach(input => input.disabled = true)
    document.querySelectorAll('textarea').forEach(textarea => textarea.disabled = true)
    document.querySelectorAll('.clickable_td').forEach(td => td.style.pointerEvents = 'none')

    // select boxes
    const selects = document.querySelectorAll('select')
    selects.forEach(select => {
      select.disabled = true;
      if (select.classList.contains('tomselected')) {
        select.tomselect.disable();
      }
    });

    // Save and Cancel buttons
    document.querySelectorAll('input[type="submit"], .btn-danger')
      .forEach(button => button.disabled = true)
  }
}
