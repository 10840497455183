import { Controller } from '@hotwired/stimulus'
import formChanges from 'shared/form_changes'

export default class extends Controller {
  static targets = ['form']
  static values = { skipCheck: { type: Boolean, default: false } }

  static SAVE_PROMPT_SELECTOR = '.form-unsaved-changes-prompt'

  connect() {
    if(this.formTargets.length === 0) {
      return
    }

    this.beforeUnloadListener = this.handleBeforeUnload.bind(this)
    window.addEventListener('beforeunload', this.beforeUnloadListener)

    this.formTargets.forEach(form => {
      form.addEventListener('submit', this.formSubmit.bind(this))
    })
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.beforeUnloadListener)
  }

  handleBeforeUnload(event) {
    //console.log(`handle 1111 skipCheckValue: ${this.skipCheckValue} | this.#anyFormDirty(): ${this.#anyFormDirty()}`)

    if(!this.skipCheckValue && this.#anyFormDirty()) {
      event.preventDefault()

      this.#showSavePrompt()

      this.removePassDirtyFromForms()

      event.returnValue = true
      return true
    }
  }

  removePassDirtyFromForms() {
    this.formTargets.forEach(form => {
      form.removeAttribute('data-pass-dirty')
    })
  }

  formSubmit(event) {
    const form = event.target

    if(form.getAttribute('data-turbo') === 'true') {
      return
    }

    form.setAttribute('data-pass-dirty', 'true')

    if(this.#anyOtherFormDirty(form)) {
      //event.preventDefault()
      event.stopPropagation()

      return
    }
  }

  #showSavePrompt() {
    const firstDirtyForm = this.#firstDirtyForm()
    const savePromptEl = firstDirtyForm.querySelector(this.constructor.SAVE_PROMPT_SELECTOR)

    if(savePromptEl) {
      savePromptEl.classList.remove('d-none')
      savePromptEl.scrollIntoView()
    }
  }

  #formsDirty() {
    return this.formTargets.filter(form => this.#isFormDirty(form))
  }

  #firstDirtyForm() {
    return this.#formsDirty().find(form => !form.hasAttribute('data-pass-dirty'))
  }

  #anyFormDirty() {
    return this.#formsDirty().filter(form => !form.hasAttribute('data-pass-dirty')).length > 0
  }

  #anyOtherFormDirty(form) {
    return this.#formsDirty().some(otherForm => otherForm !== form)
  }

  #isFormDirty(form) {
    const form_changes = formChanges(form)
    const changed = form_changes && form_changes.length > 0
    return changed
  }

  // callback for form_unsaved_changes:skip_check
  // disable page leave prompt
  receiveSkipCheck(event) {
    this.skipCheckValue = event.detail.value
  }

  // callback for modal-dismiss events
  // if user resigns from saving form we should re-enable back page leave prompt
  receiveModalDismiss() {
    this.skipCheckValue = false
  }
}
