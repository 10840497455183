// used in non-medical job plans, job content form
// repeats_every_n_weeks (select), repeats_weekly_each_days_of_the_week (day boxes),
// repeats_rota (week circles) management
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'everyWeeks' ]

  connect(){
    // get value of repeats_every_n_weeks select 'Every' (1..52) 'week(s)' and use to show relevant week circles
    const everyWeeks = this.everyWeeksTarget.value;
    this.pluralizeWeek(everyWeeks);
    if (everyWeeks > 1){
      this.hideAllWeekCircles();
      document.getElementById('repeats-rota').classList.remove('d-none')
      this.showWeekCircles(everyWeeks);
    }

    // get value of hidden repeats_rota select [(1..52)] and use to select week circles
    const repeatsRotaElement = document.getElementById('job_content_repeats_rota');
    const repeats_rota = Array.from(repeatsRotaElement.selectedOptions).map(option => option.value);
    this.selectWeekCircles(repeats_rota);

    // get value of 'repeats_weekly_each_days_of_the_week' select (Mon-Sun) and use to highlight selected days
    const daysSelect = document.getElementById('job_content_repeats_weekly_each_days_of_the_week');
    const selectedDays = Array.from(daysSelect.selectedOptions).map(option => option.value);
    this.selectDaysOfTheWeek(selectedDays);
  }

  // when select an option from the on 'Every' (1..52) 'week(s)' select
  onSelectEveryWeeksOption(event){
    const everyWeeks = event.target.value;
    this.pluralizeWeek(event.target.value);

    const rotaElement = document.getElementById('repeats-rota');
    if (everyWeeks > 1) {
      rotaElement.classList.remove('d-none');
    } else {
      rotaElement.classList.add('d-none');
    }

    this.hideAllWeekCircles();
    this.showWeekCircles(everyWeeks);
  }

  // when click on week circle
  onClickWeekCircle(event) {
    // Toggle selected class
    event.target.classList.toggle('selected');

    // Get all selected weeks using native JS
    const selected_weeks = Array.from(event.target.closest('tr').querySelectorAll('td.selected'))
      .map(td => td.dataset.value);

    // Update the multi-select element
    const selectElement = document.getElementById('job_content_repeats_rota')

    // Clear existing selections
    Array.from(selectElement.options).forEach(option => {
      option.selected = false;
    });

    // Select the new options
    selected_weeks.forEach(week => {
      const option = selectElement.querySelector(`option[value="${week}"]`)
      if (option) option.selected = true;
    });
  }

  // when click on day of the week
  onClickDay(event){
    // add selected class
    event.target.classList.toggle('selected');

    // update hidden array field using native JS
    const selectedDays = Array.from(event.target.closest('tr').querySelectorAll('td.selected'))
      .map(td => td.dataset.value);

    // Get the select element
    const selectElement = document.getElementById('job_content_repeats_weekly_each_days_of_the_week');

    // Clear all existing selections
    Array.from(selectElement.options).forEach(option => {
      option.selected = false
    })

    // Select the new options
    selectedDays.forEach(day => {
      const option = selectElement.querySelector(`option[value="${day}"]`)
      if (option) option.selected = true;
    })
  }

  hideAllWeekCircles() {
    document.querySelectorAll('.rota-week-selector .clickable_td')
      .forEach(el => el.classList.add('d-none'))
  }

  showWeekCircles(everyWeeks) {
    if (everyWeeks > 1) {
      this.hideAllWeekCircles();
      document.getElementById('repeats-rota').classList.remove('d-none')

      const range = Array.from({length: parseInt(everyWeeks)}, (_, i) => i + 1);
      range.forEach(value => {
        document.querySelector(`td[data-value='${value}']`)?.classList.remove('d-none')
      });
    }
  }

  selectWeekCircles(repeats_rota) {
    if (repeats_rota) {
      repeats_rota.forEach(value => {
        document.querySelector(`td[data-value='${value}']`)?.classList.add('selected')
      });
    }
  }

  selectDaysOfTheWeek(daysOfTheWeek) {
    if (daysOfTheWeek) {
      daysOfTheWeek.forEach(value => {
        document.querySelector(`td[data-value="${value}"]`)?.classList.add('selected')
      })
    }
  }

  pluralizeWeek(count) {
    const weekElements = document.querySelectorAll('.weeks_on')
    weekElements.forEach(element => {
      element.textContent = Number(count) > 1 ? 'weeks' : 'week'
    });
  }
}
