// used in admin/csv_imports

import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String, regexp: String }
  static targets = ['result']

  fileUploadSelectedCallback(event) {
    const fileName = event.detail.fileNames[0]
    this.#checkNumber(fileName)
  }

  async #checkNumber(filename) {
    this.resultTarget.innerHTML = ''

    const gmcNumber = filename.match(new RegExp(this.regexpValue))[1]
    const url = `${this.urlValue}?filename=${gmcNumber}`

    const response = await get(url, {
      contentType: 'application/json',
      responseKind: 'json'
    })

    if (!response.ok) return

    const data = await response.json
    const message = data
      ? `Found user with council membership: ${gmcNumber}`
      : `No user with council membership: ${gmcNumber}`

    this.resultTarget.innerHTML = `<p>${message}</p>`
  }
}
