// copied from eRostering, move to sard core when refactored to work with both

import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['input']
  static SELECTORS = 'input[type="text"][id^="date_"], input[type="text"][id$="_date"], input[type="text"][id*="_date_"]'

  connect() {
    //console.log('flatpickr | connect |')

    if (document.querySelector('meta[name="pdf"]')) return

    this.apply()

    //this.element.addEventListener('hidden.bs.modal', function (event) {
    addEventListener('shown.bs.modal', this.apply.bind(this))

    // addEventListener('turbo:frame-render', () => {
    //   console.log('flatpickr | connect | turbo:frame-render')
    //   this.apply()
    // })

    // addEventListener('turbo:frame-load', () => {
    //   console.log('flatpickr | connect | turbo:frame-load')
    // })

    // addEventListener('turbo:render', (event) => {
    //   console.log('flatpickr | connect | turbo:render', event)
    // })

    // addEventListener('turbo:load', (event) => {
    //   console.log('flatpickr | connect | turbo:load', event)
    // })
  }

  // inputTargetConnected(event) {
  //   console.log('flatpickr | inputTargetConnected |', event)
  // }

  apply() {
    document.querySelectorAll(this.constructor.SELECTORS).forEach(input => {
      flatpickr(input, this.config())
    })

    this.inputTargets.forEach(input_target => {
      //console.log('flatpickr | apply | input_target', input_target)
      flatpickr(input_target, this.config())
    })
  }

  disconnect() {
    //document.removeEventListener("turbo:load", this.applyCallbacks.bind(this));
    //document.removeEventListener("turbo:frame-render", this.apply.bind(this))
    document.removeEventListener('shown.bs.modal', this.apply)
  }

  config() {
    const config = {
      enableTime: false,
      allowInput: true,
      dateFormat: 'd/m/Y',
      disableMobile: true,
      locale: {
        firstDayOfWeek: 1
      }
    }

    return config
  }
}
