// disable job plan form when complete
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { disableForm: { type: Boolean, default: false } }

  connect() {
    if(this.disableFormValue){
      this.disableForm()
    }
  }

  disableForm() {
    // Disable form inputs (not comments - can add those to complete job plans)

    const sectionContentInputs = document.querySelectorAll(".section-content input")
    sectionContentInputs.forEach(input => {
      input.disabled = true
    })

    const submitButton = document.querySelector('.section-content [type="submit"]')
    submitButton.disabled = true

    const cancelButton = document.querySelector(".section-content .btn-cancel")
    cancelButton.disabled = true

    const selectElements = document.querySelectorAll('select')
    selectElements.forEach(select => {
      if(select.classList.contains('tomselected')) {
        select.tomselect.disable()
      }
    })
  }
}
