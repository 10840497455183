// responsible for confirmation modals show
// atm used only in job plan items delete actions

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { confirmationUrl: String }

  connect() {
    //console.log('confirmations | url | this.element', this.element, this.confirmationUrlValue, this.element.dataset)

    const deleteButtons = this.element.querySelectorAll('a[data-turbo-method=delete]')

    // assign confirmation modal to all delete buttons within controller context
    _.each(deleteButtons, (el) => {
      el.setAttribute('data-action', 'click->confirmations#showConfirmationModal')
      el.setAttribute('data-confirmations-source-action-name-param', 'delete')

      // we don't want default confirmations since we use custom modal in that scenario
      el.removeAttribute('data-turbo-confirm')
      el.removeAttribute('data-confirm')
    })

    addEventListener('modal:confirm', (event) => {
      //console.log('confirmations | catched modal:confirm', event, event.currentTarget)
      //this._receiveModalConfirm(event.detail.confirm_button)
      this.receiveModalConfirm(event)
    })
  }

  showConfirmationModal(event) {
    event.preventDefault()

    // pass source action name, because depending on this, trigger item delete or form post
    this.fetchAndDisplayModal(event.params.sourceActionName, event.currentTarget.href) // TODO different for form ?
  }

  receiveModalConfirm(event) {
    //console.log('confirmations | _receiveModalConfirm | event', event)

    const sourceActionName = event.detail.source_action_name
    const sourceUrl = event.detail.source_url

    if(sourceActionName == 'delete') {
      fetch(sourceUrl, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': this._getCsrfToken(),
          Accept: "text/vnd.turbo-stream.html"
        }
      })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
    }
  }

  async fetchAndDisplayModal(source_action_name, source_url) {
    await fetch(`${this.confirmationUrlValue}?source_action_name=${source_action_name}&source_url=${source_url}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  _getCsrfToken() {
    return document.querySelector("[name='csrf-token']").getAttribute('content')
  }
}
