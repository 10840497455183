// toggle between repeats weekly, monthly and never
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'repeatsSelect' ]

  connect(){
    this.toggleRepeats(this.repeatsSelectTarget.value)
  }

  onSelectRepeatsOption(event) {
    this.toggleRepeats(event.target.value)
  }

  toggleRepeats(repeats) {
    const monthlyElement = document.querySelector('#repeats-monthly');
    const weeklyElement = document.querySelector('#repeats-weekly');
    const everyElements = document.querySelectorAll('.repeats-every');

    switch (repeats) {
      case 'weekly':
        monthlyElement.classList.add('d-none');
        weeklyElement.classList.remove('d-none');
        everyElements.forEach(el => el.classList.remove('d-none'));
        break;
      case 'monthly':
        monthlyElement.classList.remove('d-none');
        weeklyElement.classList.add('d-none');
        everyElements.forEach(el => el.classList.remove('d-none'));
        break;
      case 'never':
        everyElements.forEach(el => el.classList.add('d-none'));
        break;
    }
  }
}
