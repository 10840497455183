import { Controller } from '@hotwired/stimulus'
import { TableBuilderView } from "../views/views.js"
import { TableElementTemplate, ExpandedTableElementTemplate, PopoverTemplate } from "../templates/templates.js"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.fetchAndDisplayTable()
  }

  async fetchAndDisplayTable() {
    const response = await get(this.urlValue, {
      headers: {
        contentType: 'application/json'
      }
    })

    if(response.ok) {
      this.tableData = await response.json
      this.table_builder = new TableBuilderView(this.tableData)
      this.element.innerHTML = this.table_builder.render()
      this.setNormalContent()
    } else {
      this.element.innerHTML = response.statusText
    }
  }

  addPopovers() {
    _.each(this.table_builder.tables, function(table) {
      _.each(table.rows, function(row) {
        _.each(_.filter(row, 'id'), function(element) {
          const job_content = this.table_builder.template_data[element.id].job_content
          const hours = this.table_builder.template_data[element.id].hours

          const html = PopoverTemplate({
            scheduled: job_content.scheduled,
            start_time: job_content.start_time,
            end_time: job_content.end_time,
            categorisation: job_content.categorisation,
            summary: job_content.summary,
            hours
          })

          const selector = `[id="${element.id}"] .activity`
          const el = this.element.querySelector(selector)
          let customClass = job_content.categorisation.toLowerCase()

          let options = {
            container: this.element,
            content: html,
            html: true,
            placement: 'auto',
            trigger: 'hover',
            // trigger: 'click', // Used for styling
            customClass: customClass + ' timetable-week-overview'
          }

          new bootstrap.Popover(el, options)
        }, this)
      }, this)
    }, this)
  }

  toggleExpand() {
    if (this.expanded) {
      this.setNormalContent()
      this.expanded = false
    } else {
      this.setExpandedContent()
      this.expanded = true
    }
  }

  setNormalContent() {
    _.each(this.table_builder.tables, function(table) {
      _.each(table.rows, function(row) {
        _.each(_.filter(row, 'id'), function(element) {
          const job_content = this.table_builder.template_data[element.id].job_content
          const hours = this.table_builder.template_data[element.id].hours
          const overlaps_midnight_class = this.table_builder.template_data[element.id].overlaps_midnight_class

          const html = TableElementTemplate({
            job_plan_id: this.element.dataset.jobPlanId,
            job_content_id: job_content.id,
            categorisation: job_content.categorisation.toLowerCase(),
            one_line_summary: job_content.one_line_summary,
            hours,
            overlaps_midnight_class
          })

          this.element.querySelector(`[id="${element.id}"]`).innerHTML = html
        }, this)
      }, this)
    }, this)
    this.addPopovers()
  }

  setExpandedContent() {
    _.each(this.table_builder.tables, function(table) {
      _.each(table.rows, function(row) {
        _.each(_.filter(row, 'id'), function(element) {
          const job_content = this.table_builder.template_data[element.id].job_content
          const hours = this.table_builder.template_data[element.id].hours
          const overlaps_midnight_class = this.table_builder.template_data[element.id].overlaps_midnight_class

          const html = ExpandedTableElementTemplate({
            job_plan_id: this.element.dataset.jobPlanId,
            job_content_id: job_content.id,
            scheduled: job_content.scheduled,
            start_time: job_content.start_time,
            end_time: job_content.end_time,
            categorisation: job_content.categorisation,
            summary: job_content.summary,
            hours,
            overlaps_midnight_class
          })

          this.element.querySelector(`[id="${element.id}"]`).innerHTML = html
        }, this)
      }, this)
    }, this)
  }
}
