export default function TableElementTemplate(data) {
  return `
    <div class="session ${data.overlaps_midnight_class}">
      <a class="activity ${data.categorisation}"
        href="/non_medical/online_job_plans/${data.job_plan_id}/activities/${data.job_content_id}/edit"
        data-turbo="true"
        data-turbo-frame="section-container">
        <span class="header_one_line">
          ${data.hours}
        </span>
        ${data.one_line_summary}
      </a>
    </div>
  `
}