import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

import * as d3 from 'd3'

export default class extends Controller {
  static values = { partitionDataBaseUrl: String, activityType: String, activityUnits: String,
                    defaultUserScope: Object,
                    group1: String, group2: String, group3: String,
                    csrfToken: String,
                    userIds: Array, onlineJobPlanIds: Array }

  static ROW_HIGHLIGHT_FACTOR = 0.4

  connect() {
    this.color = d3.scaleOrdinal(d3.schemeCategory10)

    this.luminance = d3.scaleSqrt()
            .domain([0, 1e6])
            .clamp(true)
            .range([90, 30])

    this.setColours()

    // set part of the url which does not change when clicking table rows
    this.baseUrl = new URL(this.partitionDataBaseUrlValue)

    this.baseUrl.searchParams.set('activity_type', this.activityTypeValue)
    this.baseUrl.searchParams.set('activity_units', this.activityUnitsValue)
    this.baseUrl.searchParams.set('group1', this.group1Value)
    this.baseUrl.searchParams.set('group2', this.group2Value)
    this.baseUrl.searchParams.set('group3', this.group3Value)
  }

  clickRow(event) {
    event.preventDefault()
    this.dispatch('row-clicked')

    const selectedPartitions = event.params.selectedPartitions
    this.renderPartitionTable(selectedPartitions)
  }

  chartSliceClickedCallback(event) {
    const id = event.detail.id
    const tr = this.element.querySelector(`tr[id = '${id}']`)

    tr.click()
  }

  // we use custom solution here exceptionally because we need to pass sometimes a lot of user/online_job_plan_ids,
  // which can be too long for GET to handle
  renderPartitionTable(selectedPartitions) {
    let url = this.baseUrl

    url.searchParams.delete('selected_partitions[]')
    selectedPartitions.forEach(partition => {
      url.searchParams.append('selected_partitions[]', partition)
    })

    // Turbo.visit(url.toString(), { acceptsStreamResponse: true })

    let data = {
      user_ids: this.userIdsValue,
      online_job_plan_ids: this.onlineJobPlanIdsValue
    }

    if(this.defaultUserScopeValue) {
      data.default_user_scope = this.defaultUserScopeValue
    }

    this.renderTurboStreamFromUrl(url.toString(), data)
  }

  renderTurboStreamFromUrl(url, data) {
    //const body = new URLSearchParams(data)
    const body = JSON.stringify(data)

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': "text/vnd.turbo-stream.html",
        'X-CSRF-Token': this.csrfTokenValue
      },
      body: body
    })
    .then(r => r.text())
    .then(html => {
      html = `<turbo-stream action="update" target="partition-table-container"><template>${html}</template></turbo-stream>`
      Turbo.renderStreamMessage(html)
    })
  }

  setColours() {
    let hasCustomCssClass, depth, id, colour, value, hue = null

    this.element.querySelectorAll('tr').forEach(row => {
      hasCustomCssClass = row.getAttribute('data-has-custom-css-class') === 'true'
      depth = row.dataset.depth

      if(hasCustomCssClass) {
        return
      }

      if(depth == 1) {
        id = row.getAttribute('id')
        hue = this.color(id)
        colour = d3.lab(hue)
        value = row.dataset.value
        colour.l = this.luminance(value)
      }

      row.style.backgroundColor = colour
    })
  }

  mouseoverRow(event) {
    this.dispatch('row-mouseover', { detail: { id: event.params.id } } )

    const tr = event.target.closest('tr')
    this.hightlightRow(tr)
  }

  hightlightRow(row) {
    const currentColor = this.getRowColor(row)
    const darkerColor = currentColor.darker(this.constructor.ROW_HIGHLIGHT_FACTOR)

    row.style.backgroundColor = darkerColor
  }

  mouseoutRow(event) {
    this.dispatch('row-mouseout')

    // unhighlight the row
    const tr = event.target.closest('tr')
    const currentColor = this.getRowColor(tr)
    const brighterColor = currentColor.brighter(this.constructor.ROW_HIGHLIGHT_FACTOR)

    tr.style.backgroundColor = brighterColor
  }

  getRowColor(tr) {
    const bgC = d3.select(tr).style('background-color')
    return d3.color(bgC)
  }
}
