import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }
  static targets = ['tenantId', 'ownerId']

  async updateDoctorOptions(event) {
    //console.log('updateDoctorOptions', event.target.value)
    const tenantId = event.target.value
    const ts = this.ownerIdTarget.tomselect

    if (!tenantId) {
      this.#clearDoctorOptions(ts)
      return
    }

    ts.disable()

    const url = `${this.urlValue}?tenant_id=${tenantId}`
    const response = await get(url, {
      contentType: 'application/json',
      responseKind: 'json'
    })

    if (response.ok) {
      const data = await response.json
      this.#updateSelectOptions(data.tenant_users, ts)
    }

    ts.enable()
  }

  #clearDoctorOptions(ts) {
    ts.clear()
    ts.clearOptions()
  }

  #updateSelectOptions(users, ts) {
    ts.clear()
    ts.clearOptions()

    const options = users.map(([value, text]) => ({ value, text }))
    ts.addOptions(options)
  }
}
