// used in Timetable/SPA Notes, Additional PAs, Additional/External/On Call Availability Supplement sections
// TODO: refactor to base controller used also for timetable_week_overview controller

import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { TableBuilderView } from "../../views/views.js"
import { TableElementTemplate, ExpandedTableElementTemplate, PopoverTemplate } from "../../templates/online_job_plan/templates.js"

export default class extends Controller {
  static targets = ['timetable']
  static values = { url: String,
                    sortMethod: { type: String, default: 'order'},
                    queryParams: Array, // e.g. ['categorisation', 'SPA'] or ['categorisation', ['AR', 'ED']]
                    editJobContentPathTemplate: String,
                    customTableName: String }

  connect() {
    this.fetchAndDisplayTable()
  }

  async fetchAndDisplayTable() {
    const response = await get(this.urlValue, {
      headers: {
        contentType: 'application/json'
      }
    })

    if(response.ok) {
      const data = await response.json

      let attr_name = this.queryParamsValue[0]
      let query = this.queryParamsValue[1]

      if(!Array.isArray(query)) {
        query = [query]
      }

      const table_data = _.filter(data, (a) => query.indexOf(a[attr_name]) != -1)

      let options = { sort_method: this.sortMethodValue }
      if(this.customTableNameValue) {
        options.custom_table_name = this.customTableNameValue
      }

      if(table_data.length > 0) {
        this.table_builder = new TableBuilderView(table_data, options)
        this.timetableTarget.innerHTML = this.table_builder.render()
        this.setNormalContent()
      }
    } else {
      this.timetableTarget.innerHTML = response.statusText
    }
  }

  addPopovers() {
    _.each(this.table_builder.tables, function(table) {
      _.each(table.rows, function(row) {
        _.each(_.filter(row, 'id'), function(element) {
          const job_content = this.table_builder.template_data[element.id].job_content
          const hours_long = this.table_builder.template_data[element.id].hours_long
          const pas = this.table_builder.template_data[element.id].pas
          const impacted_hours = this.table_builder.template_data[element.id].impacted_hours
          const impacted_pas = this.table_builder.template_data[element.id].impacted_pas

          const html = PopoverTemplate({
            scheduled: job_content.scheduled,
            start_time: job_content.start_time,
            end_time: job_content.end_time,
            categorisation: job_content.categorisation,
            summary: job_content.summary,
            hours_long,
            pas,
            impacted_hours,
            impacted_pas
          })

          const selector = `[id="${element.id}"] .activity`
          const el = this.element.querySelector(selector)
          const customClass = job_content.categorisation.toLowerCase()

          const options = {
            container: this.element,
            content: html,
            html: true,
            placement: 'auto',
            trigger: 'hover',
            customClass: `${customClass} timetable-week-overview`,
          }

          new bootstrap.Popover(el, options)
        }, this)
      }, this)
    }, this)
  }

  setNormalContent() {
    _.each(this.table_builder.tables, function(table) {
      _.each(table.rows, function(row) {
        _.each(_.filter(row, 'id'), function(element) {
          const job_content = this.table_builder.template_data[element.id].job_content
          const hours = this.table_builder.template_data[element.id].hours
          const pas = this.table_builder.template_data[element.id].pas
          const overlaps_midnight_class = this.table_builder.template_data[element.id].overlaps_midnight_class
          const impacted_hours = this.table_builder.template_data[element.id].impacted_hours
          const impacted_pas = this.table_builder.template_data[element.id].impacted_pas

          const html = TableElementTemplate({
            edit_job_content_path: this.prepareEditJobContentPath(job_content.id),
            categorisation: job_content.categorisation.toLowerCase(),
            one_line_summary: job_content.one_line_summary,
            hours,
            pas,
            impacted_hours,
            impacted_pas,
            overlaps_midnight_class,
          })

          this.element.querySelector(`[id="${element.id}"]`).innerHTML = html
        }, this)
      }, this)
    }, this)
    this.addPopovers()
  }

  prepareEditJobContentPath(job_content_id) {
    return this.editJobContentPathTemplateValue.replace('JOB_CONTENT_ID', job_content_id)
  }
}