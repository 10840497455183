import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { newUrl: String }
  static targets = ['fromMsf', 'toMsf']

  async handleFromTenantChange(event) {
    const tenantId = event.target.value
    const ts = this.fromMsfTarget.tomselect

    if (!tenantId) {
      this.#clearMsfOptions(ts)
      return
    }

    ts.disable()

    const response = await get(this.newUrlValue, {
      query: { tenant_id: tenantId },
      contentType: 'application/json',
      responseKind: 'json'
    })

    if (response.ok) {
      const data = await response.json
      this.#updateMsfOptions(ts, data.tenant_msfs)
    }

    ts.enable()
  }

  async handleToTenantChange(event) {
    const tenantId = event.target.value
    const ts = this.toMsfTarget.tomselect

    if (!tenantId) {
      this.#clearMsfOptions(ts)
      return
    }

    const response = await get(this.newUrlValue, {
      query: { tenant_id: tenantId },
      contentType: 'application/json',
      responseKind: 'json'
    })

    if (response.ok) {
      const data = await response.json
      this.#updateMsfOptions(ts, data.tenant_msfs)
    }
  }

  #clearMsfOptions(ts) {
    ts.clear()
    ts.clearOptions()
  }

  #updateMsfOptions(ts, msfData) {
    this.#clearMsfOptions(ts)
    const options = msfData.map(item => ({ value: item[1], text: item[0] }))
    ts.addOptions(options)
  }
}
