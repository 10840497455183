// toggle between scheduled and flexible
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'scheduledRadioYes' ]

  connect(){
    this.toggleScheduledFlexible(this.scheduledRadioYesTarget.checked);
  }

  onSelectActivityType(event) {
    this.toggleScheduledFlexible(event.target.value == 'true')
  }

  toggleScheduledFlexible(isScheduled){
    const flexibleElements = document.querySelectorAll('.flexible')
    const scheduledElements = document.querySelectorAll('.scheduled')

    if(isScheduled == true){
      flexibleElements.forEach(el => el.classList.add('d-none'))
      scheduledElements.forEach(el => el.classList.remove('d-none'))
      this.removeAnnualOptionFromRepeatsSelect();
    } else {
      flexibleElements.forEach(el => el.classList.remove('d-none'))
      scheduledElements.forEach(el => el.classList.add('d-none'))
      this.addAnnualOptionFromRepeatsSelect();
    }
  }

  removeAnnualOptionFromRepeatsSelect(){
    const select = document.querySelector('#job_content_repeats')
    const option = select.querySelector('option[value="never"]')
    if(option) {
      option.remove();
    }
  }

  addAnnualOptionFromRepeatsSelect(){
    const select = document.querySelector('#job_content_repeats')
    const option = select.querySelector('option[value="never"]')
    if(!option) {
      const newOption = document.createElement('option')
      newOption.value = 'never';
      newOption.textContent = 'Never';
      select.appendChild(newOption);
    }
  }
}
