// used in non-medical job plans, job content form

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect(){
    // Get selected values using vanilla JS
    const selectElement = document.getElementById('job_content_repeats_monthly_on_days_of_the_week')
    const days_of_the_week = Array.from(selectElement.selectedOptions).map(option => option.value)
    this.selectDaysOfTheWeek(days_of_the_week)

    const everyMonths = document.getElementById('job_content_repeats_every_n_months').value
    this.pluralizeMonth(everyMonths)
  }

  // when click on day of the week
  onClickDay(event){
    // Toggle selected class using classList
    event.target.classList.toggle('selected')

    // Get all selected days using modern selectors
    const selectedDays = Array.from(
      event.target.closest('tr').querySelectorAll('td.selected')
    ).map(td => td.dataset.value);

    // Update hidden field value
    document.getElementById('job_content_repeats_monthly_on_days_of_the_week').value = selectedDays
  }

  onSelectEveryMonthsOption(event){
    this.pluralizeMonth(event.target.value);
  }

  selectDaysOfTheWeek(daysOfTheWeek) {
    if (daysOfTheWeek) {
      daysOfTheWeek.forEach(value => {
        document.querySelector(`td[data-value='${value}']`).classList.add('selected')
      })
    }
  }

  pluralizeMonth(count) {
    const monthElement = document.querySelector('.months')
    const text = count > 1 ? 'months' : 'month'
    monthElement.textContent = text
  }
}
