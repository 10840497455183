import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { requestUrl: String }
  static targets = ['fromTenant', 'toTenant', 'fromUser', 'toUser', 'submitButton']

  connect() {
    this.updateUserOptions(this.fromTenantTarget, this.fromUserTarget)
    this.updateUserOptions(this.toTenantTarget, this.toUserTarget)
  }

  updateUserOptions(tenantSelect, userSelect) {
    tenantSelect.addEventListener('change', () => {
      fetch(`${this.requestUrlValue}?tenant_id=${tenantSelect.value}`, {
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json"
        }
      })
      .then(response => response.json())
      .then(data => {
        const ts = userSelect.tomselect

        ts.clear()
        ts.clearOptions()

        const options = data.tenant_users.map(item => ({ value: item[1], text: item[0] }))
        ts.addOptions(options)
      })
    })
  }
}
